import { Box, Typography } from '@mui/material';
import React from 'react';

interface TabLabelProps {
  label: string;
  counter?: number;
}

const TabLabel: React.FC<TabLabelProps> = ({ counter, label }) => {
  return (
    <>
      <Typography fontWeight={600} fontSize="14px" lineHeight="20px">
        {label}
      </Typography>
      {!!counter && (
        <Box bgcolor="#FEF3F2" borderRadius="16px" padding="2px 8px">
          <Typography fontWeight={500} fontSize="12px" lineHeight="18px" color="#B42318">
            {counter}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default TabLabel;
