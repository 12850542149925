import { Box, Typography } from '@mui/material';
import { Order } from '../../../order.types';
import OrderSummaryItem from './OrderSummaryItem';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../../../constants/language';

const OrderSummary: React.FC<{ item: Order; type: string }> = ({ item, type }) => {
  const { t } = useTranslation();

  return (
    <Box bgcolor="white">
      <Box display="flex" gap="10px" alignItems="center" padding="12px">
        <Typography fontSize="14px" lineHeight="20px" color="#667085" fontWeight="500">
          {t(LANG_TEXT.ORDER_SUMMARY)}
        </Typography>
        <Box borderRight="1px solid #EAECF0" height="14px" />
        <Typography fontSize="14px" lineHeight="20px" color="#98A2B3" fontWeight="500">
          {type === 'promotion'
            ? `${item?.promotion_foods?.length ?? 0} ${item?.promotion_foods?.length ?? 0 > 1 ? t(LANG_TEXT.ORDER_ITEMS) : t(LANG_TEXT.ORDER_ITEM)}`
            : `${item.foods.length} ${item.foods.length > 1 ? t(LANG_TEXT.ORDER_ITEMS) : t(LANG_TEXT.ORDER_ITEM)}`}
        </Typography>
      </Box>
      <Box>
        {type !== 'promotion' && item.foods && item.foods.map((_item, index) => <OrderSummaryItem key={`${_item.item_food_id}-${index}`} orderItem={_item} />)}
        {type === 'promotion' &&
          item.promotion_foods &&
          item.promotion_foods.map((_item, index) => <OrderSummaryItem key={`${_item.item_food_id}-${index}`} orderItem={_item} />)}
      </Box>
    </Box>
  );
};

export default OrderSummary;
