import { Box } from '@mui/material';
import React from 'react';
import { Order } from '../../order.types';
import EmptyState from '../EmptyState';
import Loader from '../Loader';
import OrderItem from '../OrderItem';

interface OngoingTabProps {
  loading: boolean;
  data: Order[];
  onClickCTA: (item: Order) => void;
  onShowInfo: (item: Order) => void;
  emptyStateTitle: string;
  disabled?: boolean;
}

const TabContent: React.FC<OngoingTabProps> = ({ data, loading, emptyStateTitle, onClickCTA, onShowInfo, disabled }) => {
  return (
    <Box height="100%" display="flex" flexDirection="column" gap="8px">
      {loading ? (
        <Loader />
      ) : data?.length > 0 ? (
        data.map((item, index) => {
          return <OrderItem key={index} onClickCTA={onClickCTA} onShowInfo={onShowInfo} item={item} disabled={disabled} />;
        })
      ) : (
        <EmptyState title={emptyStateTitle} />
      )}
    </Box>
  );
};

export default TabContent;
