import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCM87Ll4_XTn_k_rrGrRTNoPQxIulfMtLU',
  authDomain: 'meny-stag.firebaseapp.com',
  projectId: 'meny-stag',
  storageBucket: 'meny-stag.appspot.com',
  messagingSenderId: '977714978420',
  appId: '1:977714978420:web:5130c6ce4677d4a63fbb21',
  measurementId: 'G-2S506DKC2M',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const messaging = firebase.messaging();
