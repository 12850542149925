import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { CustomButton } from '../../../../components/Button';
import CheckBox from '../../../../components/CheckBox';
import Input from '../../../../components/Input';
import { LANG_TEXT } from '../../../../constants/language';
import { useAuthContext } from '../../../../context/authContext';
import { useNotificationContext } from '../../../../context/notificationContext';
import { auth } from '../../../../firebase/config';
import { LoginFormContainer } from '../../styles';
import ForgotPasswordDialog from '../ForgotPasswordDialog';
import LanguageSelect from '../LanguageSelect';

interface ILoginForm {
  email: string;
  password: string;
  remember?: boolean;
}

const LoginForm = () => {
  const navigate = useNavigate();
  const { updateUser } = useAuthContext();
  const { showToast } = useNotificationContext();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup.string().required(LANG_TEXT.LOGIN_REQUIRED_EMAIL).email(LANG_TEXT.LOGIN_INVALID_EMAIL),
    password: yup.string().required(LANG_TEXT.LOGIN_REQUIRED_PASSWORD),
    remember: yup.boolean(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ILoginForm>({
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
    resolver: yupResolver(schema),
  });

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);

  const handleOpenForgotPassword = () => {
    setOpenForgotPasswordDialog(true);
  };
  const handleCloseForgotPassword = () => {
    setOpenForgotPasswordDialog(false);
  };
  const handleSubmitEmail = async (data: { email: string }) => {
    await auth.sendPasswordResetEmail(data.email);
    showToast({
      content: t(LANG_TEXT.FORGOT_PASSWORD_MESSAGE),
      type: 'success',
    });
    handleCloseForgotPassword();
  };

  const handleLogin = async (data: ILoginForm) => {
    try {
      const user = await auth.signInWithEmailAndPassword(data.email, data.password);
      if (user.user) {
        updateUser(user.user);

        const token = await user.user.getIdTokenResult();
        showToast({
          content: t(LANG_TEXT.LOGIN_SUCCESS_MESSAGE),
          type: 'success',
        });
        window.localStorage.setItem('id_token', token.token);

        navigate('/orders');
      }
    } catch (error: any) {
      console.error('error:', error);
      showToast({
        content: t(LANG_TEXT.LOGIN_FAIL_MESSAGE),
        type: 'error',
      });
    }
  };

  return (
    <>
      <LoginFormContainer onSubmit={handleSubmit(handleLogin)}>
        <Box>
          <Box display="flex" flexDirection="column" gap="14px">
            <Input
              control={control}
              error={errors?.email?.message || ''}
              label={t(LANG_TEXT.LOGIN_EMAIL)}
              name="email"
              placeholder={t(LANG_TEXT.LOGIN_EMAIL_PLACEHOLDER)}
            />
            <Input
              control={control}
              type="password"
              error={errors?.password?.message || ''}
              label={t(LANG_TEXT.LOGIN_PASSWORD)}
              name="password"
              placeholder={t(LANG_TEXT.LOGIN_PASSWORD_PLACEHOLDER)}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <CheckBox
              control={control}
              name="remember"
              label={
                <Typography color="#1D2939" fontWeight={600} fontSize={14}>
                  {t(LANG_TEXT.LOGIN_REMEMBER_ME)}
                </Typography>
              }
            />
            <Typography color="#296C32" fontWeight={600} fontSize={14} onClick={handleOpenForgotPassword}>
              {t(LANG_TEXT.FORGOT_PASSWORD)}
            </Typography>
          </Box>
          <Box marginTop="12px">
            <Typography fontSize={14} color="#344054" fontWeight={500}>
              {t(LANG_TEXT.SELECT_LANGUAGE)}
            </Typography>
            <LanguageSelect />
          </Box>
        </Box>
        <CustomButton variant="contained" type="submit">
          {t(LANG_TEXT.LOGIN_BUTTON)}
        </CustomButton>
      </LoginFormContainer>
      <ForgotPasswordDialog open={openForgotPasswordDialog} onClose={handleCloseForgotPassword} onSubmit={handleSubmitEmail} />
    </>
  );
};

export default LoginForm;
