import { Badge, BadgeProps, Box, ClickAwayListener, Divider, Grow, ListItem, Popper, Typography, styled } from '@mui/material';
import MuiList from '@mui/material/List';
import MuiPaper from '@mui/material/Paper';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BellIcon from '../../../assets/icons/bell-icon.svg';
import { CustomButton } from '../../../components/Button';
import { LANG_TEXT } from '../../../constants/language';
import { Message } from '../../../context/notificationContext';

const Paper = styled(MuiPaper)({
  transformOrigin: 'top left',
  backgroundImage: 'none',
  border: '1px solid',
  borderColor: '#EAECF0',
  boxShadow: `0px 4px 20px rgba(170, 180, 190, 0.3)`,
  marginTop: '2px',
});

const List = styled(MuiList)(({ theme }) => ({
  width: 250,
  maxHeight: 250,
  overflow: 'auto',
  padding: '2px 0',
}));

const Item = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#EAECF0',
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: -5,
    background: '#B42318',
  },
}));

const Notification = ({ notifications, markAsRead }: { notifications: Message[]; markAsRead: () => void }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const anchorRef = useRef(null);

  const navigate = useNavigate();

  const handleToggle = () => {
    if (!open) {
      markAsRead();
    }
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <CustomButton ref={anchorRef} padding="10px 11px" onClick={handleToggle}>
        <StyledBadge badgeContent={notifications?.filter((i) => i.isUnread).length}>
          <img src={BellIcon} width={14} height={16} alt="user" />
        </StyledBadge>
      </CustomButton>
      <Popper id="notifications-popup" sx={{ zIndex: 1000 }} anchorEl={anchorRef.current} open={open} placement="bottom-end" transition disablePortal role={undefined}>
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper>
                <List>
                  {notifications?.length ? (
                    notifications.map((message, index) => (
                      <React.Fragment key={`message-${index}`}>
                        <Item
                          alignItems="flex-start"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (message?.id) navigate(`/?id=${message.id}`);
                          }}
                        >
                          <Typography fontSize={14}>{message.message}</Typography>
                        </Item>
                        {index < notifications.length - 1 ? <Divider /> : null}
                      </React.Fragment>
                    ))
                  ) : (
                    <Box marginY="10px" paddingX="16px">
                      <Typography fontSize={14} textAlign="center">
                        {t(LANG_TEXT.NOTIFICATION_EMPTY_STATE)}
                      </Typography>
                    </Box>
                  )}
                </List>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default Notification;
