import { Box, Divider, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BoxIcon from '../../../../assets/icons/box-icon.svg';
import ClockCheckIcon from '../../../../assets/icons/clock-check-icon.svg';
import ClockIcon from '../../../../assets/icons/clock-icon.svg';
import InfoIcon from '../../../../assets/icons/info-circle-icon.svg';
import LocationIcon from '../../../../assets/icons/location-icon.svg';
import SeatIcon from '../../../../assets/icons/seat-icon.svg';
import { CustomButton } from '../../../../components/Button';
import { CustomChip } from '../../../../components/Chip';
import { LANG_TEXT } from '../../../../constants/language';
import { convertText, formatFirebaseTime } from '../../../../utils/common';
import { STATUS_COLOR_MAP } from '../../data';
import { Order, OrderItemStatus } from '../../order.types';

interface OrderItemProps {
  item: Order;
  disabled?: boolean;
  onClickCTA?: (item: Order) => void;
  onShowInfo: (item: Order) => void;
}

const OrderItem: React.FC<OrderItemProps> = ({ item, onClickCTA, onShowInfo, disabled }) => {
  const { t } = useTranslation();

  const renderButton = useMemo(() => {
    if (!item?.rider_id)
      return (
        <CustomButton
          variant="contained"
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            onClickCTA && onClickCTA(item);
          }}
        >
          {t(LANG_TEXT.PICK_UP_ORDER)}
        </CustomButton>
      );
    switch (item.order_status) {
      case OrderItemStatus.PREPARING: {
        return (
          <CustomButton variant="outlined" disabled startIcon={<img src={ClockIcon} width={17} height={17} alt="user" />}>
            {t(LANG_TEXT.PREPARING)}
          </CustomButton>
        );
      }
      case OrderItemStatus.DELIVERING: {
        return (
          <CustomButton variant="outlined" disabled startIcon={<img src={ClockIcon} width={17} height={17} alt="user" />}>
            {t(LANG_TEXT.DELIVERING)}
          </CustomButton>
        );
      }
      case OrderItemStatus.READY_FOR_PICK_UP: {
        return (
          <CustomButton
            variant="contained"
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              onClickCTA && onClickCTA(item);
            }}
          >
            {t(LANG_TEXT.PICKED_UP_FOOD)}
          </CustomButton>
        );
      }
      default: {
        return null;
      }
    }
  }, [disabled, item]);

  const status = useMemo(() => {
    const statusMap = {
      [OrderItemStatus.PREPARING]: t(LANG_TEXT.PREPARING),
      [OrderItemStatus.READY_FOR_PICK_UP]: t(LANG_TEXT.READY_FOR_PICK_UP),
      [OrderItemStatus.DELIVERING]: t(LANG_TEXT.DELIVERING),
      [OrderItemStatus.DELIVERED]: t(LANG_TEXT.DELIVERED),
    };
    return convertText(item?.rider_id ? statusMap[item.order_status] : t(LANG_TEXT.NEW));
  }, [item]);

  const total = useMemo(() => {
    const total = (item.promotion_foods?.length ?? 0) + (item.foods?.length ?? 0);
    return total;
  }, [item]);

  return (
    <Box
      sx={{ opacity: disabled ? 0.4 : 1 }}
      padding="16px"
      borderBottom="1px solid #EAECF0"
      role="button"
      onClick={() => {
        if (!disabled) onShowInfo(item);
      }}
    >
      <Box display="flex" flexDirection="column" gap="24px">
        <Box display="flex" flexDirection="column" gap="12px">
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <CustomChip size="md" bgcolor="#F2F4F7" color="#344054">
              {item.id}
            </CustomChip>
            <CustomChip
              size="md"
              bgcolor={STATUS_COLOR_MAP[item?.rider_id ? item.order_status : OrderItemStatus.PREPARING].bgColor}
              color={STATUS_COLOR_MAP[item?.rider_id ? item.order_status : OrderItemStatus.PREPARING].color}
              textTransform="capitalize"
            >
              {status}
            </CustomChip>
          </Box>
          <Typography color="#475467" lineHeight="28px" fontWeight={600} fontSize="18px">
            {item.type === 'promotion' ? item?.restaurant_promotion?.name : item?.restaurant?.name || '-'}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" gap="8px" alignItems="center">
              <img src={LocationIcon} width={24} height={24} alt="user" />
              <Typography color="#475467" lineHeight="24px" fontWeight={500} fontSize="16px">
                {item.type === 'promotion' ? item?.restaurant_promotion?.region : item?.restaurant?.region || '-'} {t(LANG_TEXT.ZONE)}
              </Typography>
            </Box>
            <Divider color="#98A2B3" orientation="vertical" flexItem />
            <Box display="flex" gap="8px" alignItems="center">
              <img src={BoxIcon} width={24} height={24} alt="user" />
              <Typography color="#475467" lineHeight="24px" fontWeight={500} fontSize="16px">
                {total} {total > 1 ? t(LANG_TEXT.ORDER_ITEMS) : t(LANG_TEXT.ORDER_ITEM)}
              </Typography>
            </Box>
            <Divider color="#98A2B3" orientation="vertical" flexItem />
            <Box display="flex" gap="8px" alignItems="center">
              <img src={SeatIcon} width={24} height={24} alt="user" />
              <Typography color="#475467" lineHeight="24px" fontWeight={500} fontSize="16px">
                {item.seat_number}
              </Typography>
            </Box>
          </Box>
        </Box>
        {renderButton}
      </Box>
      {disabled && (
        <Box display="flex" gap="8px" marginTop="8px">
          <img src={InfoIcon} width={14} height={14} alt="user" />
          <Typography color="#667085" lineHeight="18px" fontWeight={400} fontSize="12px">
            {t(LANG_TEXT.ORDER_DISABLE_TOOLTIP)}
          </Typography>
        </Box>
      )}
      {item.order_status === OrderItemStatus.DELIVERED && (
        <Box display="flex" gap="8px" marginTop="16px">
          <img src={ClockCheckIcon} width={17} height={17} alt="user" />
          <Typography color="#98A2B3" lineHeight="20px" fontWeight={400} fontSize="14px">
            {t(LANG_TEXT.ORDER_DELIVERED_AT, {
              date: formatFirebaseTime(item?.order_delivered_time?.seconds),
              interpolation: { escapeValue: false },
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default OrderItem;
