import firebase from 'firebase/compat/app';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase/config';
import DB_TABLE from '../firebase/db-config';

interface AuthContextValue {
  user: firebase.User | null;
  loading: boolean;
  isOnline: boolean;
  stadiumId: string | null;
  logoutUser: () => void;
  updateUser: (_user: firebase.User) => void;
  onToggleOnline: (e: boolean) => void;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  loading: true,
  isOnline: true,
  stadiumId: null,
  logoutUser: () => {},
  updateUser: (user) => {},
  onToggleOnline: (e) => {},
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [stadiumId, setStadiumId] = useState(null);

  const updateUser: (_user: firebase.User | null) => any = (_user) => {
    setUser(_user);
    setLoading(false);
  };

  const logoutUser = () => {
    auth.signOut();
    window.localStorage.removeItem('id_token');
    setUser(null);
  };

  const onToggleOnline = (e: boolean) => {
    if (user?.uid) {
      db.collection(DB_TABLE.DB_TABLE_USERS)
        .doc(user.uid)
        .update({ is_online: e })
        .then((res) => {
          setIsOnline(e);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged(updateUser);
  }, []);

  useEffect(() => {
    if (user?.uid) {
      db.collection(DB_TABLE.DB_TABLE_USERS)
        .doc(user.uid)
        .get()
        .then((result) => {
          const userInfo = result.data();
          if (userInfo) {
            setIsOnline(userInfo?.is_online ?? false);
            setStadiumId(userInfo.stadium_id || '');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user?.uid]);

  return (
    <AuthContext.Provider
      value={{
        user,
        stadiumId,
        loading,
        isOnline,
        logoutUser,
        updateUser,
        onToggleOnline,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  return context;
};

export { AuthProvider, useAuthContext };
