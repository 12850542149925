import { Box, Typography } from '@mui/material';
import React from 'react';
import EmptyNote from '../../../../assets/icons/empty-note.svg';

interface EmptyStateProps {
  title: string;
  icon?: any;
}

const EmptyState: React.FC<EmptyStateProps> = ({ icon, title }) => {
  return (
    <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="40px">
      <img src={EmptyNote} width={176} height={132} alt="user" />
      <Typography color="#98A2B3" fontWeight={500} fontSize="16px" lineHeight="24px">
        {title}
      </Typography>
    </Box>
  );
};

export default EmptyState;
