import { makeStyles } from '@mui/styles';

export const useStyles: any = makeStyles(() => ({
  formControl: {
    '& .MuiInputBase-root': {
      color: '#3D9F4A',
      borderColor: '#3D9F4A',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '8px',
      minWidth: '140px',
      marginBottom: '16px',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '6px 4px 6px 10px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '0px',
      },
    },
  },
  select: {
    flex: '1 1 0%',
    fontSize: '14px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIcon: {
    color: '#3D9F4A',
    fontSize: '24px',
  },
  paper: {
    borderRadius: 8,
    marginTop: 4,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontWeight: 400,
      fontSize: '14px',
    },
    '& li.Mui-selected': {
      color: 'white',
      background: '#3D9F4A',
    },
    '& li.Mui-selected:hover': {
      background: '#3D9F4A',
    },
  },
}));
