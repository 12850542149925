import { ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { AppRoutes } from './routes';
import { useAuthContext } from './context/authContext';
import Loading from './components/Loading';

const theme = createTheme({
  typography: {
    fontFamily: 'Be VietNam Pro',
  },
});

function App() {
  const { loading } = useAuthContext();

  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<Loading />}>
        {!loading ? (
          <Router>
            <AppRoutes />
          </Router>
        ) : (
          <Loading />
        )}
      </React.Suspense>
    </ThemeProvider>
  );
}

export default App;
