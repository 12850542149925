import { OrderItemStatus } from './order.types';

export const DELIVERY_FEE = 770;

export const STATUS_COLOR_MAP = {
  [OrderItemStatus.PREPARING]: {
    color: '#B42318',
    bgColor: '#FEF3F2',
  },
  [OrderItemStatus.READY_FOR_PICK_UP]: {
    color: '#B54708',
    bgColor: '#FFFAEB',
  },
  [OrderItemStatus.DELIVERING]: {
    color: '#026AA2',
    bgColor: '#F0F9FF',
  },
  [OrderItemStatus.DELIVERED]: {
    color: '#027A48',
    bgColor: '#ECFDF3',
  },
};

export const DISABLED_TOOLTIP = {
  offline: 'You are offline. Please switch to online',
  pickupFirst: 'You could only pick up this order after you pick up the first one',
  isPickingUP: 'You could only pick up this order after you pick up the first one',
};
