import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CloseIcon from '../../../../assets/icons/close-icon.svg';
import { CustomButton } from '../../../../components/Button';
import Input from '../../../../components/Input';
import { ForgotPasswordFormContainer } from '../../styles';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../../constants/language';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: ({ email }: { email: string }) => void;
}

const ForgotPasswordDialog: React.FC<DialogProps> = ({ onClose, open, onSubmit }) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup.string().required(t(LANG_TEXT.LOGIN_REQUIRED_EMAIL)).email(t(LANG_TEXT.LOGIN_INVALID_EMAIL)),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <ForgotPasswordFormContainer onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="#101828" fontWeight={600} fontSize={18}>
            {t(LANG_TEXT.FORGOT_PASSWORD_MODAL_TITLE)}
          </Typography>
          <IconButton onClick={(e) => onClose()}>
            <img src={CloseIcon} alt="close" />
          </IconButton>
        </Box>
        <Typography color="#475467" fontWeight={400} fontSize={14}>
          {t(LANG_TEXT.FORGOT_PASSWORD_MODAL_HINT)}
        </Typography>
        <Input control={control} error={errors?.email?.message || ''} label={t(LANG_TEXT.LOGIN_EMAIL)} name="email" placeholder={t(LANG_TEXT.LOGIN_EMAIL_PLACEHOLDER)} />
        <CustomButton variant="contained" type="submit">
          {t(LANG_TEXT.FORGOT_PASSWORD_MODAL_BUTTON)}
        </CustomButton>
      </ForgotPasswordFormContainer>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
