import { Timestamp } from '@firebase/firestore-types';

export interface Order {
  id?: string;
  restaurant_id: string;
  restaurant: Restaurant;
  restaurant_promotion?: Restaurant;
  bill_status: string;
  payment_method: PaymentMethod;
  order_table_number: string;
  order_id: string;
  order_create_time: Timestamp;
  order_username: string;
  order_status: OrderItemStatus;
  order_delivered_time: Timestamp;
  foods: OrderItem[];
  promotion_restaurant_id: string;
  promotion_foods: OrderItem[];
  type?: string;
  food_order_status?: string;
  food_promotion_order_status?: string;
  rider_id?: string;
  seat_type: string;
  seat_number: string;
}

export interface OrderItem {
  item_customize_settings: { name: string; price: string }[];
  item_food_id: string;
  item_name: number;
  item_note: string;
  item_price: string;
  item_quantity: number;
  item_subtotal: number;
  status: string;
}

export interface Restaurant {
  restaurant_id: string;
  restaurant_active?: string;
  region: string;
  restaurant_open_state?: number;
  name: string;
}

export interface FoodDetails {
  [food_id: string]: {
    name: string;
    image_url: string;
    description: string;
    customize_group_settings: {
      name: string;
      price: number;
    }[];
  };
}

export enum TabValue {
  NEW_ORDER = 'new order',
  ONGOING = 'ongoing',
  DELIVERED = 'delivered',
}

export enum OrderItemStatus {
  PREPARING = 'PREPARING',
  DELIVERING = 'DELIVERING',
  READY_FOR_PICK_UP = 'READY_FOR_PICK_UP',
  DELIVERED = 'DELIVERED',
}

export enum PaymentMethod {
  MOBILE_PAYMENT = 'MOBILE_PAYMENT',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum BillStatus {
  NOT_CLEARED = 'NOT_CLEARED',
  BILL_CANCELLED_AS_SHOP_CLOSED = 'BILL_CANCELLED_AS_SHOP_CLOSED',
  CLEARED = 'CLEARED',
}