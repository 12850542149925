import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomSwitch } from '../../../components/Switch';
import { LANG_TEXT } from '../../../constants/language';

const OnlineSwitch: React.FC<{ isOnline: boolean; onToggleOnline: (e: boolean) => void }> = ({ isOnline, onToggleOnline }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" bgcolor="white" minWidth="92px" borderRadius="8px" gap="8px" padding="8px 16px">
      <CustomSwitch
        checked={isOnline}
        onChange={(e) => {
          onToggleOnline(e.target.checked);
        }}
      />
      <Typography color={isOnline ? '#3D9F4A' : '#98A2B3'} fontWeight="600" fontSize="14px" lineHeight="20px">
        {isOnline ? t(LANG_TEXT.ONLINE) : t(LANG_TEXT.OFFLINE)}
      </Typography>
    </Box>
  );
};

export default OnlineSwitch;
