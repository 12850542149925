import { ClickAwayListener, Divider, Grow, Popper, Typography, styled } from '@mui/material';
import MuiPaper from '@mui/material/Paper';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LogutIcon from '../../../assets/icons/logout-icon.svg';
import UserIcon from '../../../assets/icons/user-icon.svg';
import { CustomButton } from '../../../components/Button';
import { LANG_TEXT } from '../../../constants/language';
import { useNotificationContext } from '../../../context/notificationContext';
import { db } from '../../../firebase/config';
import DB_TABLE from '../../../firebase/db-config';

const Paper = styled(MuiPaper)({
  transformOrigin: 'top left',
  backgroundImage: 'none',
  border: '1px solid',
  borderColor: '#EAECF0',
  boxShadow: `0px 4px 20px rgba(170, 180, 190, 0.3)`,
  marginTop: '2px',
  padding: '8px',
});

const UserInfo: React.FC<{ user: any; logoutUser: () => void }> = ({ user, logoutUser }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const { deviceToken } = useNotificationContext();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <CustomButton ref={anchorRef} padding="10px 11px" onClick={handleToggle}>
        <img src={UserIcon} width={14} height={16} alt="user" />
      </CustomButton>
      <Popper id="notifications-popup" sx={{ zIndex: 1000 }} anchorEl={anchorRef.current} open={open} placement="bottom-end" transition disablePortal role={undefined}>
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper>
                {user?.email && (
                  <>
                    <Typography fontSize={14}>{user?.email}</Typography>
                    <Divider sx={{ marginBottom: 1 }} />
                  </>
                )}
                <CustomButton
                  variant="outlined"
                  padding="4px 8px"
                  fullWidth
                  onClick={async (e) => {
                    logoutUser();
                    const userDocRef = db.collection(DB_TABLE.DB_TABLE_USERS).doc(user.uid);
                    const userDoc = await userDocRef.get();
                    const deviceTokens = userDoc.data()?.device_tokens || [];
                    const updatedDeviceTokens = deviceTokens.filter((token: string) => token !== deviceToken);
                    await userDocRef.update({ device_tokens: updatedDeviceTokens });
                  }}
                >
                  <img src={LogutIcon} width={14} height={16} alt="out" />
                  <Typography fontSize={14} marginLeft={1} color="#344054">
                    {t(LANG_TEXT.LOGOUT)}
                  </Typography>
                </CustomButton>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default UserInfo;
