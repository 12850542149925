import { API_URL } from './config';

export const subscribeTopic = async (topic: string, deviceToken: string[]) => {
  try {
    const res = await fetch(`${API_URL}/subscribeToTopic/${topic}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deviceToken,
      }),
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const unsubscribeFromTopic = async (topic: string, deviceToken: string[]) => {
  try {
    const res = await fetch(`${API_URL}/unsubscribeFromTopic/${topic}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deviceToken,
      }),
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const sendToTopic = async (
  topic: string,
  payload: {
    status: string;
    id: string;
  }
) => {
  try {
    const res = await fetch(`${API_URL}/sendNotifications/topic/${topic}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...payload,
      }),
    });
    return res;
  } catch (error) {
    return error;
  }
};
