import { useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useAuthContext } from '../context/authContext';

export const AppRoutes = () => {
  const { user } = useAuthContext();

  const routes = user?.uid ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};
