import { Box } from '@mui/material';
import Notification from './Notification';
import OnlineSwitch from './OnlineSwitch';
import UserInfo from './UserInfo';
import { TopBarContainer } from './styles';
import { useNotificationContext } from '../../../context/notificationContext';
import { useAuthContext } from '../../../context/authContext';

const TopBar = () => {
  const { notifications, markAsRead } = useNotificationContext();
  const { logoutUser, user, isOnline, onToggleOnline } = useAuthContext();

  return (
    <TopBarContainer display="flex" justifyContent="space-between">
      <Box display="flex" gap="12px">
        <UserInfo user={user} logoutUser={logoutUser} />
        <Notification notifications={notifications} markAsRead={markAsRead} />
      </Box>
      <OnlineSwitch isOnline={isOnline} onToggleOnline={onToggleOnline} />
    </TopBarContainer>
  );
};

export default TopBar;
