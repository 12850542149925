import { Button, ButtonProps, styled } from '@mui/material';

export const CustomButton = styled(Button)<ButtonProps & { padding?: string }>(({ theme, padding, variant }) => ({
  minWidth: 'fit-content',
  padding: padding ?? '12px',
  backgroundColor: variant === 'outlined' ? 'white' : '#30893C',
  borderColor: variant === 'outlined' ? '#D0D5DD' : '#30893C',
  textTransform: 'none',
  fontSize: '16px',
  borderRadius: '8px',
  fontWeight: 600,
  color: variant === 'outlined' ? '#D0D5DD' : 'white',

  '&:hover': {
    backgroundColor: variant === 'outlined' ? '#F9FAFB' : '#377940',
    borderColor: variant === 'outlined' ? '#98A2B3' : '#30893C',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: variant === 'outlined' ? '#F9FAFB' : '#377940',
    borderColor: variant === 'outlined' ? '#98A2B3' : '#30893C',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.1rem #fff',
  },
  '&:disabled': {
    color: variant === 'outlined' ? '#D0D5DD' : 'white',
    backgroundColor: variant === 'outlined' ? 'white' : '#C6ECCA',
    borderColor: variant === 'outlined' ? '#EAECF0' : '#30893C',
  },
}));
