import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { LANG_DISPLAY_KEY } from '../../../../constants/language';
import i18n from '../../../../context/I18n';
import { useStyles } from './styles';

const LANGUAGE_OPTIONS = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Japanese',
    value: 'ja',
  },
];

const LanguageSelect = () => {
  const classes = useStyles();

  const [language, setLanguage] = useState(localStorage.getItem(LANG_DISPLAY_KEY)?.toString() || 'ja');

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    const lang = event.target.value;
    localStorage.setItem(LANG_DISPLAY_KEY, lang);
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={language}
        onChange={handleChangeLanguage}
        disableUnderline
        MenuProps={{
          classes: {
            list: classes.list,
            paper: classes.paper,
          },

          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
        classes={{
          select: classes.select,
          icon: classes.selectIcon,
        }}
      >
        {LANGUAGE_OPTIONS.map((item) => (
          <MenuItem key={item.value} value={item.value} classes={classes.item}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
