import styled from '@emotion/styled';
import { Tab, TabProps, Tabs } from '@mui/material';

export const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    gap: '12px',
  },
  minHeight: '36px',
});

export const CustomTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  flexDirection: 'row',
  gap: '8px',
  padding: '8px 12px',
  minHeight: '36px',
  minWidth: '80px',
  borderRadius: '6px',
  backgroundColor: 'transparent',
  color: '#667085',
  '&:hover': {
    color: '#296C32',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#296C32',
    backgroundColor: '#F2FBF3',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#F2FBF3',
  },
}));
