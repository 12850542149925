import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { LANG_DISPLAY_KEY, SITES } from '../constants/language';
import { getSourceLang } from '../api/language-api';

i18n.use(initReactI18next).init({
  resources: {},
  lng: localStorage.getItem(LANG_DISPLAY_KEY) || 'ja',
  fallbackLng: 'ja',
});

export function I18nProvider({ children }: { children: ReactNode }) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [data, setData] = useState<any>();

  const getLanguage = async () => {
    try {
      const language = await getSourceLang(SITES.RIDER);
      setData(language);
    } catch (err) {
      console.log(err);
      setIsDataLoaded(true);
    }
  };

  useEffect(() => {
    getLanguage();
  }, []);

  useEffect(() => {
    if (data) {
      for (var key in data) {
        i18n.addResourceBundle(key, 'translation', data[key]);
      }
      setIsDataLoaded(true);
    }
  }, [data]);

  if (!isDataLoaded) return null;

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export default i18n;
