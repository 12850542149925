import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomChip } from '../../../../../components/Chip';
import { LANG_TEXT } from '../../../../../constants/language';
import { convertText } from '../../../../../utils/common';
import { STATUS_COLOR_MAP } from '../../../data';
import { Order, OrderItemStatus } from '../../../order.types';

const OrderIdSection: React.FC<{ item: Order }> = ({ item }) => {
  const { t } = useTranslation();

  const status = useMemo(() => {
    const statusMap = {
      [OrderItemStatus.PREPARING]: t(LANG_TEXT.PREPARING),
      [OrderItemStatus.READY_FOR_PICK_UP]: t(LANG_TEXT.READY_FOR_PICK_UP),
      [OrderItemStatus.DELIVERING]: t(LANG_TEXT.DELIVERING),
      [OrderItemStatus.DELIVERED]: t(LANG_TEXT.DELIVERED),
    };
    return convertText(item?.rider_id ? statusMap[item.order_status] : t(LANG_TEXT.NEW));
  }, [item]);

  return (
    <Box bgcolor="white" padding="16px" display="flex" flexDirection="column" alignItems="center" gap="8px" borderBottom="1px solid #EAECF0">
      <CustomChip
        size="md"
        bgcolor={STATUS_COLOR_MAP[item?.rider_id ? item.order_status : OrderItemStatus.PREPARING].bgColor}
        color={STATUS_COLOR_MAP[item?.rider_id ? item.order_status : OrderItemStatus.PREPARING].color}
        textTransform="capitalize"
      >
        {status}
      </CustomChip>
      <Box display="flex" padding="12px 0px" width="100%" flexDirection="column" alignItems="center" gap="4px" bgcolor="#F2FBF3">
        <Typography fontSize="14px" color="#667085" fontWeight="500">
          {t(LANG_TEXT.ORDER_ID)}
        </Typography>
        <Typography fontSize="30px" color="#1D2939" fontWeight="600">
          {item.id}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderIdSection;
