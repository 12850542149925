import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import BackIcon from '../../../assets/icons/arrow-left-icon.svg';
import { Order } from '../order.types';
import ActionButton from './components/ActionButton';
import OrderIdSection from './components/OrderIdSection';
import OrderSummary from './components/OrderSummary';
import PaymentMethod from './components/PaymentMethod';
import PaymentSummary from './components/PaymentSummary';
import Transition from './components/Transition';
import { CustomDrawer } from './styles';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../constants/language';

interface DeliveryInfoProps {
  item?: Order;
  open: boolean;
  onClose: () => void;
  onPickUp: (item: Order) => void;
  onConfirmPickedUp: (item: Order) => void;
  onConfirmDelivered: (item: Order) => void;
}

const DeliveryInfo: React.FC<DeliveryInfoProps> = ({ open, item, onClose, onPickUp, onConfirmDelivered, onConfirmPickedUp }) => {
  const { t } = useTranslation();
  return (
    <CustomDrawer open={open} onClose={onClose} anchor="right">
      {item && (
        <>
          <Box padding="6px 20px" borderBottom="1px solid #E5E5E5">
            <IconButton onClick={onClose}>
              <img src={BackIcon} alt="back" />
              <Typography marginLeft="12px" color="#424242" fontWeight="500">
                {t(LANG_TEXT.ORDER_DELIVERY_INFORMATION)}
              </Typography>
            </IconButton>
          </Box>
          <Box flex="1 1 0%" overflow="auto" bgcolor="#F9FAFB" display="flex" flexDirection="column" gap="8px">
            <OrderIdSection item={item} />
            <Transition item={item} type={item.type ?? 'normal'} />
            <OrderSummary item={item} type={item.type ?? 'normal'} />
            {item.promotion_foods && item.promotion_foods.length > 0 && <Transition item={item} type={'promotion'} />}
            {item.promotion_foods && item.promotion_foods.length > 0 && <OrderSummary item={item} type={'promotion'} />}
            <PaymentSummary item={item} />
            <PaymentMethod item={item} />
          </Box>
          <Box sx={{ boxShadow: '0px -2px 4px -2px #1018280F, 0px -4px 8px -2px #1018281A' }} padding="16px">
            <ActionButton item={item} onPickUp={onPickUp} onConfirmDelivered={onConfirmDelivered} onConfirmPickedUp={onConfirmPickedUp} />
          </Box>
        </>
      )}
    </CustomDrawer>
  );
};

export default DeliveryInfo;
