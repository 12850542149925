import { Navigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout/MainLayout';
// import Delivery from '../views/Delivery';
import Orders from '../views/Orders';

export const protectedRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '', element: <Orders /> },
      // { path: 'delivery/*', element: <Delivery /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
];
