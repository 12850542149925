import dayjs from 'dayjs';
import { LANG_DISPLAY_KEY } from '../constants/language';
import 'dayjs/locale/ja';

export const convertText = (text: string) => {
  return text.split('_').join(' ').toLowerCase();
};

export const formatFirebaseTime = (second: number): string => {
  const lang = localStorage.getItem(LANG_DISPLAY_KEY) || 'ja';
  const date = second ? dayjs(second * 1000) : dayjs();

  if (lang === 'ja') {
    return date.locale('ja').format('YYYY年MM月DD日, HH:mm');
  } else {
    return date.format('DD/MM/YYYY, HH:mm');
  }
};

export const formatOrderId = (orderId: string = '') => {
  return orderId.slice(0, 6);
};
