import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  value: number | string;
  active: number | string;
}

const TabPanel: React.FC<TabPanelProps> = ({ active, value, children }) => {
  return (
    <Box height="100%" role="tabpanel" hidden={active !== value} id={`simple-tabpanel-${value}`} aria-labelledby={`simple-tab-${value}`}>
      {active === value && children}
    </Box>
  );
};
export default TabPanel;
