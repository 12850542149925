import { Box, Skeleton } from '@mui/material';

const Loader: React.FC<{ rows?: number }> = ({ rows }) => {
  return (
    <>
      {[...Array(rows ?? 3)].map((item, index) => (
        <Box key={`skeleton-${index}`} padding="16px" borderBottom="1px solid #EAECF0" display="flex" flexDirection="column" gap="14px">
          <Box display="flex" flexDirection="column" gap="12px">
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Skeleton sx={{ borderRadius: '8px' }} variant="rectangular" width="80px" height={20} />
              <Skeleton sx={{ borderRadius: '8px' }} variant="rectangular" width="50px" height={20} />
            </Box>
            <Skeleton sx={{ borderRadius: '8px' }} variant="rectangular" width="100%" height={28} />
          </Box>
          <Skeleton sx={{ borderRadius: '8px' }} variant="rectangular" width="100%" height={36} />
          <Skeleton sx={{ borderRadius: '8px' }} variant="rectangular" width="100%" height={48} />
        </Box>
      ))}
    </>
  );
};

export default Loader;
