import { API_URL } from './config';

export const getSourceLang = async (site: string) => {
  try {
    const response = await fetch(`${API_URL}/getSourceLang?site=${site}`);

    const lang = await response.json();

    return lang;
  } catch (err) {
    console.log(err);
  }
};
