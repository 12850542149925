import LoginForm from './components/LoginForm';
import { LoginBanner, LoginContainer } from './styles';

const Login = () => {
  return (
    <LoginContainer>
      <LoginBanner className="login-banner" />
      <LoginForm />
    </LoginContainer>
  );
};

export default Login;
