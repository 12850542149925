import { Box, BoxProps, styled } from '@mui/material';

export const LoginContainer = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: '1024px',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
}));

export const LoginBanner = styled(Box)<BoxProps>(({ theme }) => ({
  height: 340,
}));

export const LoginFormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 16px 16px 16px',
  flex: '1 1 0%',
  justifyContent: 'space-between',
}));

export const ForgotPasswordFormContainer = styled('form')(({ theme }) => ({
  maxWidth: '320px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px 16px 16px 16px',
  position: 'relative',
}));
