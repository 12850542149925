import { Outlet } from 'react-router-dom';
import TopBar from './TopBar';
import { Box } from '@mui/material';
import { OrderProvider } from '../../context/orderContext';

const MainLayout = () => {
  return (
    <OrderProvider>
      <Box
        sx={{
          boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        }}
        height="100vh"
        maxWidth="1024px"
        margin="auto"
      >
        <TopBar />
        <Box height="calc(100vh - 52px)">
          <Outlet />
        </Box>
      </Box>
    </OrderProvider>
  );
};

export default MainLayout;
