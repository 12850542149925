const DB_TABLE_ALL_FOOD_DETAILS = 'meny_manage_all_food_details';
const DB_TABLE_ALL_ORDERS = 'meny_manage_all_orders';
const DB_TABLE_ALL_FOOD_MENU = 'meny_manage_all_food_details';
const DB_TABLE_ALL_SERVICE_REQUESTS = 'meny_manage_all_service_requests';
const DB_TABLE_ADVERTISEMENT_CONFIG = 'meny_manage_advertisement_config';
const DB_TABLE_ADVERTISEMENT_INFO = 'meny_manage_advertisement_info';
const DB_TABLE_RESTAURANT_CONFIG = 'meny_manage_restaurant_config';
const DB_TABLE_USAGE_STATISTICS = 'meny_manage_usage_statistics';
const DB_TABLE_USER_SETTINGS = 'meny_manage_user_settings';
const DB_TABLE_STADIUM_ALL_ORDERS = 'stadium_manage_all_orders';
const DB_TABLE_USERS = 'stadium_manage_users';

const DB_TABLE = {
  DB_TABLE_ADVERTISEMENT_CONFIG,
  DB_TABLE_ADVERTISEMENT_INFO,
  DB_TABLE_ALL_FOOD_DETAILS,
  DB_TABLE_ALL_FOOD_MENU,
  DB_TABLE_ALL_ORDERS,
  DB_TABLE_ALL_SERVICE_REQUESTS,
  DB_TABLE_RESTAURANT_CONFIG,
  DB_TABLE_USER_SETTINGS,
  DB_TABLE_USAGE_STATISTICS,
  DB_TABLE_STADIUM_ALL_ORDERS,
  DB_TABLE_USERS,
};

export default DB_TABLE;

export const VAPID_KEY = 'BL9gHxG9pP7aBBOOe0WPZaBzmfVzwb37M5ri0dN-BQx6BuC_hSxKUESJ9ZYqnFXomHFBd2P97O7di0sqLdggYYM';
