import { Box, Typography } from '@mui/material';
import { Order } from '../../../order.types';
import LocationIcon from '../../../../../assets/icons/location-icon.svg';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../../../constants/language';

const Transition: React.FC<{ item: Order; type: string }> = ({ item, type }) => {
  const { t } = useTranslation();

  return (
    <Box bgcolor="white" padding="16px" display="flex" gap="16px" borderBottom="1px solid #EAECF0">
      <Box display="flex" flexDirection="column" gap="4px" alignItems="center" padding="8px 0">
        <Box border="3px solid #3D9F4A" height="12px" width="12px" boxSizing="border-box" borderRadius="999px" />
        <Box borderRight="1px dashed #3D9F4A" borderLeft="1px dashed #3D9F4A" height="calc(100% - 32px)" width="1px" boxSizing="border-box" />
        <Box border="3px solid #3D9F4A" height="12px" width="12px" boxSizing="border-box" borderRadius="999px" />
      </Box>

      <Box display="flex" flexDirection="column" gap="16px">
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography fontSize="18px" lineHeight="26px" color="#1D2939" fontWeight="500">
            {type === 'promotion' ? item.restaurant_promotion?.name : item.restaurant.name}
          </Typography>
          <Box display="flex" gap="8px" alignItems="center">
            <img src={LocationIcon} width={17} height={17} alt="user" />
            <Typography fontSize="16px" lineHeight="24px" color="#667085" fontWeight="500">
              {type === 'promotion' ? item.restaurant_promotion?.region : item.restaurant.region} {t(LANG_TEXT.ZONE)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" gap="8px" flexDirection="column">
          <Typography fontSize="18px" lineHeight="26px" color="#1D2939" fontWeight="500">
            {t(LANG_TEXT.ORDER_SEAT_NUMBER)}:
          </Typography>
          <Typography fontSize="16px" lineHeight="24px" color="#1D2939" fontWeight="500">
            {item.seat_type}
          </Typography>
          <Typography fontSize="16px" lineHeight="24px" color="#1D2939" fontWeight="500">
            {item.seat_number}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Transition;
