import { Box, Typography } from '@mui/material';
import { Order } from '../../../order.types';
import { useMemo } from 'react';
import { DELIVERY_FEE } from '../../../data';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../../../constants/language';

const PaymentSummary: React.FC<{ item: Order }> = ({ item }) => {
  const { t } = useTranslation();

  const totalPrice = useMemo(() => {
    let total = 0;

    if (item.foods) {
      total += item.foods.reduce((acc, curr) => acc + parseFloat(curr.item_subtotal.toString()), 0);
    }

    if (item.promotion_foods) {
      total += item.promotion_foods.reduce((acc, curr) => acc + parseFloat(curr.item_subtotal.toString()), 0);
    }

    return total;
  }, [item.foods, item.promotion_foods]);

  return (
    <Box bgcolor="white" paddingX="16px">
      <Typography fontSize="14px" lineHeight="20px" color="#667085" fontWeight="500" paddingY="12px">
        {t(LANG_TEXT.ORDER_PAYMENT_SUMMARY)}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingY="8px">
        <Typography fontSize="12px" lineHeight="18px" color="#344054" fontWeight="500">
          {t(LANG_TEXT.ORDER_PRICE)}
        </Typography>
        <Box borderRight="1px solid #EAECF0" height="14px" />
        <Typography fontSize="14px" lineHeight="20px" color="#98A2B3" fontWeight="400">
          ¥ {totalPrice.toLocaleString('jp')}
        </Typography>
      </Box>
      {item.type !== 'promotion' && (
        <Box display="flex" justifyContent="space-between" alignItems="center" paddingY="8px" borderBottom="1px solid #EAECF0">
          <Typography fontSize="12px" lineHeight="18px" color="#344054" fontWeight="500">
            {t(LANG_TEXT.ORDER_DELIVERY_FEE)}
          </Typography>
          <Box borderRight="1px solid #EAECF0" height="14px" />
          <Typography fontSize="14px" lineHeight="20px" color="#98A2B3" fontWeight="400">
            ¥ {DELIVERY_FEE.toLocaleString('jp')}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingY="12px" borderBottom="1px solid #EAECF0">
        <Typography fontSize="14px" lineHeight="20px" color="#1D2939" fontWeight="600">
          {t(LANG_TEXT.ORDER_TOTAL_PAYMENT)}
        </Typography>
        <Typography fontSize="14px" lineHeight="20px" color="#1D2939" fontWeight="600">
          ¥ {(item.type !== 'promotion' ? totalPrice + DELIVERY_FEE : totalPrice).toLocaleString('jp')}
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentSummary;
