import React from 'react';
import ClockIcon from '../../../../../assets/icons/clock-icon.svg';
import { CustomButton } from '../../../../../components/Button';
import SwipeButton from '../../../../../components/SwipeButton/SwipeButton';
import { OrderItemStatus } from '../../../order.types';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../../../constants/language';

const ActionButton: React.FC<{ item: any; onPickUp: any; onConfirmDelivered: any; onConfirmPickedUp: any }> = ({
  item,
  onPickUp,
  onConfirmDelivered,
  onConfirmPickedUp,
}) => {
  const { t } = useTranslation();

  if (!item?.rider_id)
    return (
      <CustomButton
        fullWidth
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          onPickUp(item);
        }}
      >
        {t(LANG_TEXT.PICK_UP_ORDER)}
      </CustomButton>
    );
  switch (item?.order_status) {
    case OrderItemStatus.PREPARING: {
      return (
        <CustomButton fullWidth variant="outlined" disabled startIcon={<img src={ClockIcon} width={17} height={17} alt="user" />}>
          {t(LANG_TEXT.PREPARING)}
        </CustomButton>
      );
    }
    case OrderItemStatus.DELIVERING: {
      return (
        <SwipeButton
          slideText={t(LANG_TEXT.SLIDE_TO_MARK_AS_DONE)}
          doneText={t(LANG_TEXT.DELIVERED)}
          onSuccess={() => {
            onConfirmDelivered(item);
          }}
        />
      );
    }
    case OrderItemStatus.READY_FOR_PICK_UP: {
      return (
        <CustomButton
          fullWidth
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            onConfirmPickedUp(item);
          }}
        >
          {t(LANG_TEXT.PICKED_UP_FOOD)}
        </CustomButton>
      );
    }
    case OrderItemStatus.DELIVERED: {
      return (
        <CustomButton
          fullWidth
          variant="contained"
          disabled
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {t(LANG_TEXT.DELIVERED)}
        </CustomButton>
      );
    }
    default: {
      return null;
    }
  }
};

export default ActionButton;
